<template>
  <div
    class="columns form is-mini-gap is-multiline is-vcentered"
  >
    <!-- destination -->
    <div class="column is-narrow">
      <a-auto-complete
        v-model:value="destination"
        class="destination-auto-complete"
        option-label-prop="name"
        allow-clear
        @select="onDestinationSelect"
        @search="onDestinationsSearch"
        @change="onDestinationInputChange"
      >
        <template #dataSource>
          <a-select-option
            v-for="dest in destinations"
            :key="dest.id"
            :data="dest.data"
            :value="dest.name"
          >
            {{ dest.name }}
          </a-select-option>
        </template>
        <a-input
          :size="size"
          :placeholder="$t('Select destination')"
        >
          <template #prefix>
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.41471 12.8287C7.83796 12.8284 9.22019 12.352 10.3413 11.4753L13.8662 15L15 13.8663L11.4751 10.3416C12.3523 9.22038 12.8291 7.83791 12.8294 6.41437C12.8294 2.87765 9.95162 0 6.41471 0C2.8778 0 0 2.87765 0 6.41437C0 9.95109 2.8778 12.8287 6.41471 12.8287ZM6.41471 1.60359C9.068 1.60359 11.2257 3.76123 11.2257 6.41437C11.2257 9.06751 9.068 11.2251 6.41471 11.2251C3.76143 11.2251 1.60368 9.06751 1.60368 6.41437C1.60368 3.76123 3.76143 1.60359 6.41471 1.60359Z" fill="#757575" />
            </svg>
          </template>
        </a-input>
      </a-auto-complete>
    </div>
    <!-- checkin -->
    <div class="column is-narrow">
      <a-date-picker
        v-model:value="checkin"
        :size="size"
        :value-format="dateFormat"
        :disabled-date="disabledDate"
      />
    </div>
    <!-- guest popover -->
    <div class="column is-narrow">
      <SearchGuest
        v-model:value="guest"
        class="guest-input"
      />
    </div>
    <!-- currency  -->
    <div class="column is-narrow">
      <InputCurrency
        v-model:valueModel="currency"
        label-key="abbreviation"
        class="currency-select"
      />
    </div>
    <!-- submit button  -->
    <div class="column is-narrow">
      <a-button
        :loading="loading"
        :size="size"
        style="min-width: 150px"
        type="danger"
        :disabled="!canSubmit"
        @click="handleSubmit"
      >
        {{ isDestinationHotel ? $t('View Property') : $t('Search') }}
      </a-button>
    </div>
    <!-- filter  -->
    <div class="column is-narrow">
      <SearchFilters
        :currency="currency"
        @filter="$emit('search')"
      />
    </div>
    <div class="column is-narrow" style="margin-left: auto;">
      <Export v-if="canExport" />
    </div>
  </div>
</template>

<i18n src="../../locales.json"></i18n>

<script>
import trim from 'lodash/trim';
import dayjs from 'dayjs';
import utf8 from 'utf8';
import base64 from 'base-64';

import InputCurrency from '@/components/InputCurrency';
import SearchGuest from '@/views/simulations/components/extractions/SearchGuest';
import { cleanObject, getResponseError } from '@/utils/util';
import { getLocaleWithCountry } from '@/utils/locale';
import SearchFilters from '@/views/simulations/components/extractions/SearchFilters';
import Export from '@/views/simulations/components/extractions/Export';
import propertyMixin from '@/views/simulations/mixins/extraction-property';

export default {
  name: 'SearchForm',
  components: {
    Export,
    SearchFilters,
    InputCurrency,
    SearchGuest,
  },
  mixins: [propertyMixin],
  props: {
    loading: Boolean,
  },
  emits: ['search'],
  data() {
    return {
      destinations: [],
      destination: null,
      destinationType: null,
      regionId: null,
      checkin: null,
      currency: 'USD',
      guest: {
        adultCount: 1,
        roomCount: 1,
        children: '',
      },
      size: 'large',
      dateFormat: 'YYYY-MM-DD',
    };
  },
  computed: {
    canSubmit() {
      return this.regionId && this.checkin && this.guest?.adultCount && this.guest?.roomCount;
    },
    canExport() {
      return this.$route.query.regionId
          && this.$route.query.checkin
          && this.$route.query.adultCount
          && this.$route.query.roomCount;
    },
    isDestinationHotel() {
      return this.destinationType === 'hotel';
    },
  },
  created() {
    if (this.$route.query.destinations) {
      try {
        this.destinations = JSON.parse(utf8.decode(base64.decode(this.$route.query.destinations)));
      } catch (e) {
        // ignore
        console.log(e);
      }
    }
    this.destination = this.$route.query.destination ?? null;
    this.checkin = this.$route.query.checkin ?? null;
    this.regionId = this.$route.query.regionId ?? null;

    if (this.$route.query.currency) {
      this.currency = this.$route.query.currency;
    }

    if (this.$route.query.adultCount) {
      this.guest.adultCount = parseInt(this.$route.query.adultCount, 10);
    }

    if (this.$route.query.roomCount) {
      this.guest.roomCount = parseInt(this.$route.query.roomCount, 10);
    }

    if (this.$route.query.children) {
      this.guest.children = this.$route.query.children;
    }
  },
  methods: {
    onDestinationSelect(value, option) {
      const { key, data } = option || {};

      this.destination = value;
      this.destinationType = data?.displayType || null;
      this.regionId = key;
    },
    onDestinationInputChange(value) {
      if (!value) {
        this.regionId = null;
      }
    },
    async onDestinationsSearch(searchText) {
      try {
        const text = trim(searchText);

        if (!text) {
          this.destinations = [];
          return;
        }

        const { data } = await this.$store.dispatch('extractions/destinations', {
          query: text,
          locale: getLocaleWithCountry(this.$i18n.locale),
          limit: 5,
        });

        this.destinations = data;
      } catch (error) {
        if (error.message !== 'SIMULATIONS_DESTINATIONS') {
          this.$message.error(getResponseError(error));
        }
      }
    },
    disabledDate(current) {
      return current && dayjs().isAfter(current.format(this.dateFormat), 'days');
    },
    async handleSubmit() {
      const query = this.getQuery();

      await this.$router.push({ query });

      // redirect to property details if type is hotel
      if (this.isDestinationHotel) {
        await this.viewProperty(this.regionId);
        return;
      }

      this.$emit('search');
    },
    getQuery() {
      const query = cleanObject({
        ...this.$route.query,
        regionId: this.regionId,
        checkin: this.checkin,
        checkout: dayjs(this.checkin).add(1, 'day').format(this.dateFormat),
        currency: this.currency,
        adultCount: this.guest?.adultCount,
        roomCount: this.guest?.roomCount,
        children: this.guest?.children ?? null,
        destination: this.destination,
        destinations: base64.encode(utf8.encode(JSON.stringify(this.destinations))),
        page: 1, // force page 1
      });

      if (this.isDestinationHotel) {
        delete query.regionId;
        delete query.destination;
      }

      return query;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .ant-calendar-picker-input {
    padding-left: 2rem;
  }
  .ant-calendar-picker-icon {
    left: 12px;
    right:auto;
    color: #757575;
  }
  .ant-btn-danger {
    background-color: #BF0000;
    border-color: #BF0000;
    &:hover, &:active, &:focus {
      background-color: lighten(#BF0000, 10%);
      border-color:lighten(#BF0000, 10%);
    }
  }
  .ant-select-auto-complete {
    width: 100%;
    @include tablet {
      width: 240px;
    }
    @media (min-width: 1500px) {
      width: 330px;
    }
  }
  .ant-calendar-picker {
    width: 100%;
    @include tablet {
      width: 150px;
    }
    @media (min-width: 1500px) {
      width: 200px;
    }
  }
  .guest-input {
    width: 100%;
    @include tablet {
      width: 200px;
    }
    @media (min-width: 1500px) {
      width: 220px;
    }
  }
}
.currency-select {
  width: 100px;
  ::v-deep .ant-select {
    width: 100%;
  }
}
.form {
  max-width: 890px;
  @media (min-width: 1230px) {
    max-width: 100%;
  }
}

</style>
