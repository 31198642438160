<template>
  <a-popover
    v-model:visible="guestFormVisibility"
    title=""
    placement="bottom"
    trigger="click"
    @show="showed"
    @hide="closed"
  >
    <template #content>
      <div :class="{guestWrapperMobile: $mq === 'mobile'}">
        <div v-if="$mq === 'mobile'" class="guest__mobile-header">
          <div class="inputFullScreenHeader" @click="guestFormVisibility=false">
            <LeftOutlined /> {{ $t('title') }}
          </div>
        </div>
        <div class="guestWrapper">
          <div class="list is-gapless">
            <div class="list-content">
              <strong>{{ $tc('adult-count-label', adultCount) }}</strong>
            </div>
            <div class="list-action">
              <a-button
                type="text"
                :disabled="adultCount === 1"
                @click="handleAdult(-1)"
              >
                <MinusCircleOutlined class="is-size-4" />
              </a-button>
              <a-button
                type="text"
                @click="handleAdult(1)"
              >
                <PlusCircleOutlined class="is-size-4" />
              </a-button>
            </div>
          </div>
          <div class="list is-gapless has-border-bottom">
            <div class="list-content">
              <strong>{{ $tc('children-count-label', localChildrenCount) }}</strong>
            </div>
            <div class="list-action">
              <a-button
                type="text"
                :disabled="childrenAges.length === 0"
                @click="handleChildren(-1)"
              >
                <MinusCircleOutlined class="is-size-4" />
              </a-button>
              <a-button
                type="text"
                @click="handleChildren(1)"
              >
                <PlusCircleOutlined class="is-size-4" />
              </a-button>
            </div>
          </div>
          <div
            v-for="(n, idx) in childrenAges"
            :key="'child_' + idx"
            :class="['list is-gapless', idx + 1 === childrenAges.length ? 'has-border-bottom': '']"
          >
            <div class="list-content list-title">
              {{ $t('age-of-child') }} {{ idx + 1 }}
            </div>
            <div class="list-action">
              <a-select
                v-model:value="childrenAges[idx]"
                class="child-age"
                size="small"
                :placeholder="$t('age')"
              >
                <a-select-option
                  v-for="age in childMaxAge"
                  :key="'childAge_' + age"
                  :value="age"
                >
                  {{ age }}
                </a-select-option>
              </a-select>
            </div>
          </div>
          <div class="list is-gapless">
            <div class="list-content">
              <strong>{{ $tc('room-count-label', roomCount) }}</strong>
            </div>
            <div class="list-action">
              <a-button
                type="text"
                :disabled="roomCount === 1"
                @click="handleRooms(-1)"
              >
                <MinusCircleOutlined class="is-size-4" />
              </a-button>
              <a-button
                type="text"
                :disabled="roomCount === maxRoomCount"
                @click="handleRooms(1)"
              >
                <PlusCircleOutlined class="is-size-4" />
              </a-button>
            </div>
          </div>
          <div v-if="$mq !== 'mobile'" class="list">
            <div class="list-content" />
            <div class="list-action">
              <a-button type="text" class="btn-apply" @click="handleApply">
                {{ $t('apply') }}
              </a-button>
            </div>
          </div>
          <div v-else
               :style="doneButtonStyle"
               class="mobile-done-wrapper"
          >
            <a-button type="primary" size="large" class="btn-done" @click="handleApply">
              {{ $t('apply') }}
            </a-button>
          </div>
        </div>
      </div>
    </template>
    <a-input
      size="large"
      :readonly="true"
      :value="guests"
    />
  </a-popover>
</template>

<i18n src="../../locales.json"></i18n>

<script>
import {
  LeftOutlined,
  PlusCircleOutlined,
  MinusCircleOutlined,
} from '@ant-design/icons-vue';

const defaultRoomCount = 1;
const defaultAdultCount = 1;

export default {
  name: 'SearchGuest',
  components: {
    LeftOutlined,
    PlusCircleOutlined,
    MinusCircleOutlined,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  emits: ['update:value'],
  data() {
    return {
      guestFormVisibility: false,
      roomCount: defaultRoomCount,
      adultCount: defaultAdultCount,
      childrenAges: [],
      childMaxAge: 17,
      maxRoomCount: 8,
      doneButtonOffset: 577,
    };
  },
  computed: {
    childrenCount() {
      return this.childrenParser.length;
    },
    localChildrenCount() {
      return this.childrenAges.length;
    },
    childrenParser() {
      return this.value.children ? this.value.children.split(',') : [];
    },
    guests() {
      return [
        this.$tc('adult-count-label', this.value.adultCount),
        this.$tc('children-count-label', this.childrenCount),
        this.$tc('room-count-label', this.value.roomCount),
      ].join(', ');
    },
    doneButtonStyle() {
      return {
        position: 'absolute',
        'z-index': 999999,
        top: `${this.doneButtonOffset}px`,
      };
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(nV) {
        this.roomCount = Number.isNaN(nV.roomCount)
          ? defaultRoomCount
          : parseInt(nV.roomCount, 10);

        this.adultCount = Number.isNaN(nV.adultCount)
          ? defaultAdultCount
          : parseInt(nV.adultCount, 10);

        this.childrenAges = nV.children
          ? nV.children.split(',')
          : [];
      },
    },
  },
  mounted() {
    this.$nextTick(this.getButtonPosition);
    window.addEventListener('resize', this.getButtonPosition);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.getButtonPosition);
  },
  methods: {
    getButtonPosition() {
      this.doneButtonOffset = document.documentElement.clientHeight - 90;
    },
    showed() {
      this.reset();
      this.$emit('opened');
    },
    closed() {
      this.$emit('closed');
    },
    reset() {
      this.roomCount = Number.isNaN(this.value.roomCount)
        ? defaultRoomCount
        : parseInt(this.value.roomCount, 10);

      this.adultCount = Number.isNaN(this.value.adultCount)
        ? defaultAdultCount
        : parseInt(this.value.adultCount, 10);

      this.childrenAges = this.value.children
        ? this.value.children.split(',')
        : [];
    },
    handleRooms(val) {
      this.roomCount = Math.min(this.roomCount + val, this.maxRoomCount);
      if (this.roomCount > this.adultCount) {
        this.adultCount = this.roomCount;
      }
    },
    handleAdult(val) {
      this.adultCount += val;
      if (this.adultCount < this.roomCount) {
        this.roomCount = this.adultCount;
      }
    },
    handleChildren(val) {
      if (val > 0) {
        this.childrenAges.push(6);
      } else {
        this.childrenAges.pop();
      }
    },
    handleApply() {
      this.$emit('update:value', {
        adultCount: this.adultCount,
        roomCount: this.roomCount,
        children: this.childrenAges.join(','),
      });
      this.guestFormVisibility = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.inputFullScreenHeader {
  position: relative;
  text-align: center;
  font-weight: 700;
  padding: 13px 0;
  font-size: 16px;
  line-height: 1.5;
  color: #fff;
}

.guestWrapperMobile {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $white;

  .guestWrapper {
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 90px;

    .has-border-bottom {
      border: none !important;
    }
  }
}

.guest__mobile-header {
  height: 50px;
  background-color: $primary;
  padding-left: 25px;
  padding-right: 25px;
}

.guestWrapper {
  .btn-apply {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.child-age {
  width: 70px;
}

.ant-popover-title {
  display: none !important;
}

.mobile-done-wrapper {
  left: 0;
  right: 0;
  height: 90px;
  padding: 20px 25px;
  background-color: #fff;
}

.btn-done {
  display: block;
  width: 100%;
}

::v-deep {
  .ant-btn-text {
    padding: 0 !important;
    border: none !important;
    background-color: transparent !important;
  }

  .ant-btn + .ant-btn {
    margin-left: 15px;
    @include mobile {
      margin-left: 35px;
    }
  }
}
</style>
