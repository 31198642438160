export default {
  methods: {
    async viewProperty(propertyCode) {
      const { query } = this.$route;

      this.$store.dispatch('routers/addFirstLevelQuery', {
        query,
        name: 'simulation-extractions',
      });

      await this.$router.push({
        query,
        name: 'simulation-extractions-property',
        params: { id: propertyCode },
      });
    },
  },
};
