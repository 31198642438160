<template>
  <div v-if="floorRatings > 0" class="star-ratings">
    <i
      v-for="n in floorRatings"
      :key="'start' + n" class="star-ratings-item"
    />
  </div>
  <div v-else class="star-ratings">
    <i
      v-for="n in 5"
      :key="'start' + n" class="star-ratings-item-off"
    />
  </div>
</template>

<script>
export default {
  name: 'StarRatings',
  props: {
    ratings: {
      type: Number, // required: true,
      default: 0,
    },
  },
  computed: {
    floorRatings() {
      return Math.floor(this.ratings);
    },
  },
};
</script>

<style lang="scss" scoped>
  .star-ratings {
    display: inline-block;
    vertical-align: middle;
  }
  .star-ratings-item {
    width: 10px;
    height: 10px;
    display: inline-block;
    background: url("../assets/images/icon-star-on.svg") center center no-repeat;
    background-size: contain;
    margin-right: 2px;
  }
  .star-ratings-item-off {
    width: 10px;
    height: 10px;
    display: inline-block;
    background: url("../assets/images/icon-star-off.svg") center center no-repeat;
    background-size: contain;
    margin-right: 2px;
  }
</style>
