<template>
  <a-popover
    v-model:visible="filterFormVisibility"
    title=""
    placement="bottom"
    trigger="click"
    @show="showed"
    @hide="closed"
  >
    <template #content>
      <div
        class="filters-form"
        :class="{guestWrapperMobile: $mq === 'mobile'}"
      >
        <!--price filter-->
        <div class="mb-5">
          <div class="is-flex is-align-items-center is-justify-content-space-between">
            <div class="is-size-7 has-text-weight-bold">
              {{ $t('Price per night') }}
            </div>
            <div v-show="canClearPrice" class="is-size-8 has-text-weight-bold">
              <a @click.prevent="clearPrice">{{ $t('CLEAR') }}</a>
            </div>
          </div>
          <div>
            <a-slider v-model:value="price" range :min="min" :max="max" />
            <div class="is-flex is-align-items-center is-justify-content-space-between">
              <a-input-number
                v-model:value="price[0]"
                style="width: 120px;"
                :min="min"
                :max="price[1]"
                :step="1"
                :formatter="value => `${currency ? currency + ' ': ''}${value}`"
                :parser="value => value.replace(currency, '').replace(/\s?/g, '')"
              />
              <div>
                <svg width="14" height="1" viewBox="0 0 14 1" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <line y1="0.5" x2="14" y2="0.5" stroke="#757575" />
                </svg>
              </div>
              <a-input-number
                v-model:value="price[1]"
                style="width: 120px;"
                :min="price[0]"
                :max="max"
                :step="1"
                :formatter="value => `${currency ? currency + ' ': ''}${value}`"
                :parser="value => value.replace(currency, '').replace(/\s?/g, '')"
              />
            </div>
          </div>
        </div>
        <!--scores filter-->
        <div class="mb-4">
          <div class="is-flex is-align-items-center is-justify-content-space-between mb-3">
            <div class="is-size-7 has-text-weight-bold">
              {{ $t('Reviews') }}
            </div>
            <div v-show="canClearScores" class="is-size-8 has-text-weight-bold">
              <a @click.prevent="clearScores">{{ $t('CLEAR') }}</a>
            </div>
          </div>
          <div>
            <a-checkbox-group v-model:value="scores" :options="scoreOptions">
              <template #label="{ labelC, color }">
                <span :style="`color: ${color}`">{{ $t(labelC) }}</span>
              </template>
            </a-checkbox-group>
          </div>
        </div>
        <!--stars filter-->
        <div class="mb-4">
          <div class="is-flex is-align-items-center is-justify-content-space-between mb-3">
            <div class="is-size-7 has-text-weight-bold">
              {{ $t('Star rating') }}
            </div>
            <div v-show="canClearStars" class="is-size-8 has-text-weight-bold">
              <a @click.prevent="clearStars">{{ $t('CLEAR') }}</a>
            </div>
          </div>
          <div>
            <a-checkbox-group v-model:value="stars" :options="starOptions">
              <template #label="{ value }">
                <span v-if="value===0">{{ $t('No rating') }}</span>
                <StarRatings v-else :ratings="value" />
              </template>
            </a-checkbox-group>
          </div>
        </div>
      </div>
      <div class="has-text-right">
        <a-button
          size="large"
          class="button-secondary-outline m-r-3x"
          style="min-width: 115px"
          @click="filterFormVisibility=false"
        >
          {{ $t('Cancel') }}
        </a-button>
        <a-button
          size="large"
          style="min-width: 115px"
          type="primary"
          @click="handleSubmit"
        >
          {{ $t('Save') }}
        </a-button>
      </div>
    </template>
    <a class="has-text-white pl-4">
      <span class="mr-1 has-text-weight-bold is-uppercase">{{ $t('Filter') }}</span>
      <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 0L5 5L10 0H0Z" fill="white" />
      </svg>
    </a>
  </a-popover>
</template>

<i18n src="../../locales.json"></i18n>

<script>
import { cleanObject } from '@/utils/util';
import StarRatings from '@/components/StarRatings';

const MIN = 0;
const MAX = 50000;
export default {
  name: 'SearchFilters',
  components: { StarRatings },
  props: {
    currency: {
      type: String,
      default: 'JPY',
    },
  },
  emits: ['filter', 'opened', 'closed'],
  data() {
    return {
      filterFormVisibility: false,
      min: MIN,
      max: MAX,
      price: [MIN, MAX],
      scores: [],
      stars: [],
      scoreOptions: [
        {
          value: 'excellent',
          labelC: 'Excellent',
          color: '#047205',
        },
        {
          value: 'very good',
          labelC: 'Very Good',
          color: '#047205',
        },
        {
          value: 'good',
          labelC: 'Good',
          color: '#047205',
        },
        {
          value: 'fair',
          labelC: 'Fair',
          color: '#A35E04',
        },
        {
          value: 'poor',
          labelC: 'Poor',
          color: '#DF0101',
        },
        {
          value: 'no reviews',
          labelC: 'No Reviews',
          color: '#333333',
        },
      ],
      starOptions: [
        {
          value: 5,
        },
        {
          value: 4,
        },
        {
          value: 3,
        },
        {
          value: 2,
        },
        {
          value: 1,
        },
        {
          value: 0,
        },
      ],

    };
  },
  computed: {
    canClearPrice() {
      return !(this.price[0] === this.min && this.price[1] === this.max);
    },
    canClearScores() {
      return this.scores?.length > 0;
    },
    canClearStars() {
      return this.stars?.length > 0;
    },
  },
  created() {
    this.reset();
  },
  methods: {
    showed() {
      this.reset();
      this.$emit('opened');
    },
    closed() {
      this.$emit('closed');
    },
    reset() {
      const { price, scores, stars } = this.$route.query;
      if (price) {
        this.price = price.split('-').map((price) => Number(price));
      }

      if (scores) {
        this.scores = scores.toLowerCase().split(',');
      }

      if (stars) {
        this.stars = stars.split(',').map((star) => Number(star));
      }
    },
    clearPrice() {
      this.price = [this.min, this.max];
    },
    clearScores() {
      this.scores = [];
    },
    clearStars() {
      this.stars = [];
    },
    async handleSubmit() {
      const query = cleanObject({
        ...this.$route.query,
        currency: this.currency,
        price: this.canClearPrice ? this.price.join('-') : undefined,
        scores: this.scores.length ? this.scores.join(',') : undefined,
        stars: this.stars.length ? this.stars.join(',') : undefined,
        page: 1, // force page 1
      });

      this.filterFormVisibility = false;

      await this.$router.push({ query });

      this.$emit('filter');
    },
  },
};
</script>

<style lang="scss" scoped>
  .filters-form {
    width: 280px;
  }
  ::v-deep .ant-checkbox-group-item {
    display: block;
    margin-bottom: 5px;
  }
</style>
