<template>
  <div class="is-size-7">
    <span class="mr-2">{{ $t('TrustYou Review') }} </span>
    <strong class="has-text-weight-bold mr-2"> {{ label }}, {{ score }}/100</strong>
    <span>{{ $t('reviews', {count: $filters.number(reviewsCount)}) }}</span>
  </div>
</template>

<script>
export default {
  name: 'TrustYouReview',
  props: {
    score: {
      type: Number,
      default() {
        return 0;
      },
    },
    reviewsCount: {
      type: Number,
      default() {
        return 0;
      },
    },
    label: {
      type: String,
      default() {
        return '';
      },
    },
  },
};
</script>

<i18n>
{
"en": {
"TrustYou Review": "TrustYou Review",
"reviews": "{count} reviews"
},
"ja": {
"TrustYou Review": "TrustYou 口コミ",
"reviews": "{count} 口コミ"
}
}
</i18n>

<style scoped>

</style>
