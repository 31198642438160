<template>
  <a-button
    class="ant-btn ant-btn-lg ant-btn-black"
    type="primary"
    @click="visible=true"
  >
    {{ $t('Export to CSV') }}
  </a-button>
  <a-modal
    v-model:visible="visible"
    :footer="null"
    closable
    mask-closable
    destroy-on-close
    mask
    @cancel="handleCancel"
  >
    <div>
      <h5 class="is-size-4 has-text-weight-bold hast-text-primary mb-4">
        {{ $t('Export to CSV') }}
      </h5>
      <div class="mb-6">
        <a-radio-group
          v-model:value="full"
          size="large"
        >
          <a-radio
            v-for="option in fullOptions"
            :key="option.label"
            :style="radioStyle"
            :value="option.value"
          >
            {{ option.label }}
          </a-radio>
        </a-radio-group>
      </div>
      <div class="has-text-right">
        <a-button
          size="large"
          class="button-secondary-outline m-r-3x"
          style="min-width: 115px"
          @click="handleCancel"
        >
          {{ $t('Cancel') }}
        </a-button>
        <a-button
          size="large"
          style="min-width: 115px"
          type="primary"
          @click="handleExportCSV"
        >
          {{ $t('Confirm') }}
        </a-button>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { saveAs } from 'file-saver';
import { getResponseError } from '@/utils/util';
import { getLocaleWithCountry } from '@/utils/locale';

export default {
  name: 'Export',
  data() {
    return {
      full: true,
      visible: false,
    };
  },
  computed: {
    radioStyle() {
      return {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
      };
    },
    fullOptions() {
      const options = [
        {
          value: true,
          label: this.$t('Export to csv including detail info'),
        },
      ];

      if (this.$route.name === 'simulation-extractions') {
        options.push({
          value: false,
          label: this.$t('Export to csv excluding detail info'),
        });
      }

      return options;
    },
  },
  methods: {
    handleCancel() {
      this.full = true;
      this.visible = false;
    },
    async handleExportCSV() {
      this.$store.commit('SHOW_FULLSCREEN_LOADER');

      try {
        const { data, headers } = await this.$store.dispatch(
          'extractions/extractionsExport',
          {
            ...this.$route.query,
            full: this.full ?? undefined,
            locale: getLocaleWithCountry(this.$i18n.locale),
          },
        );

        this.$store.commit('HIDE_FULLSCREEN_LOADER');

        const attachment = headers['content-disposition'];

        if (!attachment) {
          this.handleExportCSV();

          return;
        }

        const newBlob = new Blob([data]);
        const filename = attachment.replace('attachment; filename=', '');

        saveAs(newBlob, filename);

        this.handleCancel();
      } catch (e) {
        this.$message.error(getResponseError(e));
        this.$store.commit('HIDE_FULLSCREEN_LOADER');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ant-btn-black {
  background-color: #000000!important;
  border-color: #000000!important;
}
</style>
