<template>
  <div class="is-flex is-align-items-center">
    <svg class="mr-1" width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.9 0C2.191 0 0 2.191 0 4.9C0 8.575 4.9 14 4.9 14C4.9 14 9.8 8.575 9.8 4.9C9.8 2.191 7.609 0 4.9 0ZM4.9 6.65C4.43587 6.65 3.99075 6.46563 3.66256 6.13744C3.33437 5.80925 3.15 5.36413 3.15 4.9C3.15 4.43587 3.33437 3.99075 3.66256 3.66256C3.99075 3.33437 4.43587 3.15 4.9 3.15C5.36413 3.15 5.80925 3.33437 6.13744 3.66256C6.46563 3.99075 6.65 4.43587 6.65 4.9C6.65 5.36413 6.46563 5.80925 6.13744 6.13744C5.80925 6.46563 5.36413 6.65 4.9 6.65Z" fill="#333333" />
    </svg>
    <span class="is-size-7">
      {{ address }}, {{ city }}, &nbsp;
      <template v-if="stateProvince">
        {{ stateProvince }},&nbsp;
      </template>
      {{ $filters.countryNameByIso(country) }} {{ postalCode }} ({{ latitude }}, {{ longitude }})
    </span>
  </div>
</template>

<script>

export default {
  name: 'PropertyLocation',
  props: {
    address: {
      type: String,
      default() {
        return '';
      },
    },
    city: {
      type: String,
      default() {
        return '';
      },
    },
    stateProvince: {
      type: String,
      default() {
        return '';
      },
    },
    postalCode: {
      type: String,
      default() {
        return '';
      },
    },
    countryCode: {
      type: String,
      default() {
        return '';
      },
    },
    latitude: {
      type: Number,
      default() {
        return null;
      },
    },
    longitude: {
      type: Number,
      default() {
        return null;
      },
    },
  },
};
</script>

<style scoped>

</style>
